import React, { useState, useEffect } from 'react';
import { Wind, Twitter, Send, Menu, X, Copy, AlertTriangle, Calculator, Info } from 'lucide-react';
import './styles/globals.css';
import PropTypes from 'prop-types';

const DiscordIcon = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 127.14 96.36"
    className={className}
    fill="none"
    stroke="currentColor"
    strokeWidth="12"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M107.7,8.07A105.15,105.15,0,0,0,81.47,0a72.06,72.06,0,0,0-3.36,6.83A97.68,97.68,0,0,0,49,6.83,72.37,72.37,0,0,0,45.64,0,105.89,105.89,0,0,0,19.39,8.09C2.79,32.65-1.71,56.6.54,80.21h0A105.73,105.73,0,0,0,32.71,96.36,77.7,77.7,0,0,0,39.6,85.25a68.42,68.42,0,0,1-10.85-5.18c.91-.66,1.8-1.34,2.66-2a75.57,75.57,0,0,0,64.32,0c.87.71,1.76,1.39,2.66,2a68.68,68.68,0,0,1-10.87,5.19,77,77,0,0,0,6.89,11.1A105.25,105.25,0,0,0,126.6,80.22h0C129.24,52.84,122.09,29.11,107.7,8.07ZM42.45,65.69C36.18,65.69,31,60,31,53s5-12.74,11.43-12.74S54,46,53.89,53,48.84,65.69,42.45,65.69Zm42.24,0C78.41,65.69,73.25,60,73.25,53s5-12.74,11.44-12.74S96.23,46,96.12,53,91.08,65.69,84.69,65.69Z" />
  </svg>
);

DiscordIcon.propTypes = {
  className: PropTypes.string
};

const ParticleEffect = () => {
  const particleCount = window.innerWidth < 768 ? 25 : 50;
  
  const generateParticles = () => Array.from({ length: particleCount }, (_, i) => ({
    id: i,
    x: Math.random() * 100,
    y: Math.random() * 100,
    size: Math.random() * 4 + 1,
    speed: Math.random() * 0.5 + 0.2,
    sinOffset: Math.random() * Math.PI * 2,
    opacity: Math.random() * 0.5 + 0.2
  }));

  const [particles, setParticles] = useState(generateParticles());

  useEffect(() => {
    let animationFrame;
    let timestamp = 0;
    let lastUpdate = 0;
    const FPS = 30;
    const frameDelay = 1000 / FPS;

    const animate = (currentTime) => {
      if (currentTime - lastUpdate >= frameDelay) {
        timestamp += 1;
        setParticles(prev =>
          prev.map(particle => {
            const newY = particle.y + particle.speed;
            return {
              ...particle,
              y: newY > 100 ? -5 : newY,
              x: particle.x + Math.sin(timestamp * 0.02 + particle.sinOffset) * 0.1,
              opacity: 0.2 + Math.sin(timestamp * 0.01 + particle.sinOffset) * 0.3
            };
          })
        );
        lastUpdate = currentTime;
      }
      animationFrame = requestAnimationFrame(animate);
    };

    animate(0);
    return () => {
      if (animationFrame) {
        cancelAnimationFrame(animationFrame);
      }
    };
  }, []);

  return (
    <div className="fixed inset-0 pointer-events-none overflow-hidden">
      {particles.map(particle => (
        <div
          key={particle.id}
          className="absolute rounded-full bg-blue-400 transform-gpu"
          style={{
            left: `${particle.x}%`,
            top: `${particle.y}%`,
            width: `${particle.size}px`,
            height: `${particle.size}px`,
            opacity: particle.opacity,
            filter: 'blur(1px)',
            transform: `rotate(${particle.y * 2}deg)`,
            transition: 'all 0.1s linear'
          }}
        />
      ))}
    </div>
  );
};

const GlassCard = React.memo(({ children, className = "" }) => (
  <div
    className={`relative overflow-hidden rounded-xl backdrop-blur-md bg-white/5 border border-white/10 shadow-xl ${className}`}
  >
    <div className="absolute inset-0 opacity-20 bg-gradient-to-br from-blue-400 to-transparent pointer-events-none" />
    <div className="relative z-10">{children}</div>
  </div>
));

GlassCard.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
};

const FloatingText = React.memo(() => {
  const letters = "SOON".split('');
  
  return (
    <div className="flex items-center justify-center space-x-2 sm:space-x-4 md:space-x-6">
      {letters.map((letter, index) => (
        <span
          key={index}
          className="text-6xl sm:text-8xl md:text-[120px] font-bold animate-float inline-block"
          style={{
            animationDelay: `${index * 0.2}s`,
            textShadow: '0 0 40px rgba(59, 130, 246, 0.5), 0 0 80px rgba(59, 130, 246, 0.3)',
            background: 'linear-gradient(135deg, #60a5fa 0%, #a78bfa 100%)',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
            transform: 'translateZ(0)'
          }}
        >
          {letter}
        </span>
      ))}
    </div>
  );
});

FloatingText.displayName = 'FloatingText';

const SocialLinks = React.memo(({ isMobile = false }) => {
  const socialLinks = [
    { href: "https://x.com/CroStormDotWS", Icon: Twitter, label: "Twitter" },
    { href: "https://t.me/CroStormDotWS", Icon: Send, label: "Telegram" },
    { href: "https://x.com/CroStormDotWS", Icon: DiscordIcon, label: "Discord" }
  ];

  return (
    <div className={`flex ${isMobile ? 'justify-center gap-12' : 'space-x-6'}`}>
      {socialLinks.map(({ href, Icon, label }) => (
        <a
          key={label}
          href={href}
          target="_blank"
          rel="noopener noreferrer"
          className={`transition-transform hover:scale-110 group ${
            isMobile ? 'flex flex-col items-center space-y-2' : ''
          }`}
        >
          <Icon className={`h-6 w-6 text-blue-400 group-hover:text-blue-300 ${
            isMobile ? '' : 'transform-gpu'
          }`} />
          {isMobile && <span className="text-sm text-blue-300 hover:text-blue-200">{label}</span>}
        </a>
      ))}
    </div>
  );
});

SocialLinks.propTypes = {
  isMobile: PropTypes.bool
};

SocialLinks.displayName = 'SocialLinks';

const FeatureTags = React.memo(({ className = "" }) => {
  const features = [
    { text: "ZK-SNARK Inspiration", delay: "0ms", color: "blue" },
    { text: "Military-Grade Encryption", delay: "200ms", color: "purple" },
    { text: "Dynamic Reward Pool", delay: "400ms", color: "blue" }
  ];

  return (
    <div className={`flex flex-wrap justify-center gap-4 px-4 ${className}`}>
      {features.map(({ text, delay, color }) => (
        <span
          key={text}
          className={`px-4 py-2 rounded-xl bg-${color}-500/10 border border-${color}-500/20 text-${color}-300 animate-pulse`}
          style={{ animationDelay: delay }}
        >
          {text}
        </span>
      ))}
    </div>
  );
});

FeatureTags.propTypes = {
  className: PropTypes.string
};

FeatureTags.displayName = 'FeatureTags';

const App = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    setInitialized(true);
    
    const handleResize = () => {
      if (window.innerWidth >= 768) {
        setMobileMenuOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="min-h-screen bg-gradient-to-br from-slate-900 via-blue-900 to-slate-900 text-white relative overflow-hidden">
      <ParticleEffect />

      <header className="sticky top-0 z-50 backdrop-blur-md bg-black/20 border-b border-white/10">
        <nav className="max-w-7xl mx-auto px-4 py-4">
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-3">
              <div className="relative group">
                <img
                  src="/img/logo3.png"
                  alt="Logo"
                  className="h-10 w-10 sm:h-12 sm:w-12 md:h-16 md:w-16 transition-transform duration-300 group-hover:scale-110"
                />
                <div className="absolute inset-0 bg-blue-600/30 blur-2xl rounded-full opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
              </div>
              <span className="text-xl sm:text-2xl font-bold bg-gradient-to-r from-blue-400 via-purple-400 to-blue-400 bg-clip-text text-transparent animate-gradient">
                CRO Storm
              </span>
            </div>

            <div className="hidden md:block">
              <SocialLinks />
            </div>

            <button
              className="md:hidden relative group p-2 rounded-lg overflow-hidden"
              onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
              aria-label="Toggle menu"
            >
              {mobileMenuOpen ? (
                <X className="h-6 w-6 text-blue-400" />
              ) : (
                <Menu className="h-6 w-6 text-blue-400" />
              )}
            </button>
          </div>
        </nav>

        {mobileMenuOpen && (
          <div className="md:hidden">
            <GlassCard className="mx-4 my-2 p-4">
              <SocialLinks isMobile />
            </GlassCard>
          </div>
        )}
      </header>

      <main className="flex flex-col justify-center items-center min-h-[calc(100vh-136px)] relative z-10">
        <div className={`text-center max-w-4xl mx-auto px-4 transform transition-all duration-1000 ${
          initialized ? 'translate-y-0 opacity-100' : 'translate-y-10 opacity-0'
        }`}>
          <div className="space-y-12 sm:space-y-16">
            <div className="space-y-6 sm:space-y-10">
              <h1 className="text-4xl sm:text-5xl md:text-7xl font-bold leading-tight">
                <span className="bg-gradient-to-r from-blue-400 via-purple-400 to-blue-400 bg-clip-text text-transparent animate-gradient">
                  Welcome to Storm
                </span>
              </h1>

              <p className="text-lg sm:text-xl md:text-3xl font-medium bg-gradient-to-r from-blue-400 to-purple-400 bg-clip-text text-transparent">
                Hybrid Privacy-Enhanced Staking Protocol
              </p>

              <FeatureTags />

              <p className="text-base sm:text-lg text-blue-300/80 max-w-2xl mx-auto px-4">
                Combining privacy with rewards of staking - the first hybrid protocol in the Cronos ecosystem
              </p>


            </div>

            <div className="pt-4 sm:pt-8">
              <FloatingText />
            </div>
          </div>
        </div>
      </main>

      <footer className="fixed bottom-0 w-full border-t border-white/10 backdrop-blur-md bg-black/20">
        <div className="max-w-7xl mx-auto px-4 py-4">
          <div className="flex justify-center items-center">
            <p className="text-sm sm:text-base text-blue-300/80">
              © 2024 CRO Storm. All rights reserved.
            </p>
          </div>
        </div>
      </footer>

     
    </div>
  );
};

App.displayName = 'App';

GlassCard.displayName = 'GlassCard';
ParticleEffect.displayName = 'ParticleEffect';

export default App;